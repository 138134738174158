<template>
  <div>
    <b-card class="text-center" v-if="isDownloading" body-class="p-1">
      <b-spinner small variant="primary" label="Spinning"></b-spinner>
      <span class="text-primary ml-2">Downloading...</span>
    </b-card>
    <b-card
      v-else
      no-body
      class="chat-container border-0"
      :align="chat[5] === 'Bot' ? 'right' : 'left'"
    >
      <b-card-sub-title>
        <span class="chat-sub-header"
          >{{ chat[5] + ', ' }} {{ chat[3] | chatDate }}</span
        >
      </b-card-sub-title>
      <b-card-text>
        <div class="chat-text-container">
          <a
            v-if="file.signedUrl"
            :href="file.signedUrl"
            target="_blank"
            @click.prevent.stop="
              downloadFile(file.signedUrl, file.originalFileName)
            "
          >
            <b-img
              v-if="isImageFile(file.originalFileName)"
              :src="file.signedUrl"
              fluid
              alt="Fluid image"
            ></b-img>
            <span v-else>
              {{ file.originalFileName }}
            </span>
          </a>
          <div v-else class="chat-text" :class="{ chatBot: chat[5] === 'Bot' }">
            <span v-html="chatMessageSanitized"></span>
          </div>
        </div>
      </b-card-text>
    </b-card>
  </div>
</template>

<script>
/*global $*/
import dayjs from 'dayjs';
import _ from 'lodash';
import sanitizeHtml from 'sanitize-html';
import httpHandler from '@/mixins/HttpHandler';

export default {
  name: 'ChatTextItem',
  props: {
    chat: Array,
  },
  mixins: [httpHandler],
  data() {
    return {
      file: _defaultFile(),
      isDownloading: false,
    };
  },
  mounted() {
    this.loadFileUrl();
  },
  watch: {
    chat() {
      this.loadFileUrl();
    },
  },
  computed: {
    chatMessageSanitized() {
      return sanitizeHtml(this.chatMessage, {
        allowedTags: ['a', 'br', 'p', 'strong', 'em', 'u', 'span'],
        allowedAttributes: {
          a: ['href', 'data-id', 'style'],
          span: ['style'],
          p: ['style'],
          em: ['style'],
          u: ['style'],
          strong: ['style'],
        },
        allowedSchemesAppliedToAttributes: ['src', 'cite'],
      });
    },
    chatMessage() {
      try {
        const chat = _.cloneDeep(this.chat);
        let jsonObj = JSON.parse(chat[11]);
        if (jsonObj && typeof jsonObj === 'object') {
          if ($.isNumeric(jsonObj.event)) {
            if (jsonObj.event === 0) {
              return jsonObj.question || '';
            } else if (jsonObj.event === 1) {
              return (
                '<b>Intent Selected: </b><br/> Intent ID: ' + jsonObj.intentId
              );
            } else if (jsonObj.event === 2) {
              return '::location shared::';
            } else if (jsonObj.event === 3) {
              let respond = '<i>Form replied:</i><br/>';
              let formValues = jsonObj.formValues;
              for (let i = 0; i < formValues.length; i++) {
                respond =
                  respond +
                  '<span>' +
                  formValues[i].name +
                  ' : ' +
                  formValues[i].value +
                  '</span><br/>';
              }
              return respond;
            } else if (jsonObj.event === 4) {
              return '::user joined chat::';
            }
          } else if (jsonObj.type) {
            if (jsonObj.type === 'text') {
              let message = jsonObj.content.message;
              let newMessage = '';
              let links = jsonObj.content.links || [];
              if (links.length > 0) {
                let currentPosition = 0;
                _.forEach(_.sortBy(links, ['startPosition']), function (link) {
                  newMessage =
                    newMessage +
                    message.substring(currentPosition, link.startPosition);
                  //forcing it to +1 because of c1
                  let endPosition = link.endPosition + 1;
                  let text = message.substring(link.startPosition, endPosition);
                  let type = link.type;
                  let linkStr;

                  if (type === 'goToIntent') {
                    linkStr = $('<a></a>', {
                      text: text,
                      href: '#',
                      title: 'Intent ID: ' + link.intentId,
                    }).prop('outerHTML');
                  } else if (type === 'hyperlink') {
                    linkStr = $('<a></a>', {
                      text: text,
                      title: 'Url: ' + link.url,
                      href: '#',
                    }).prop('outerHTML');
                  }

                  newMessage = newMessage + linkStr;
                  currentPosition = endPosition;
                });

                if (currentPosition < message.length) {
                  newMessage = newMessage + message.substring(currentPosition);
                }
              } else {
                newMessage = message || '';
              }

              return newMessage;
            } else if (jsonObj.type === 'quickreply') {
              return jsonObj.content.items[0].text;
            } else if (jsonObj.type === 'image') {
              return (
                '<img style="max-width: 100%;" src="' +
                jsonObj.content.url +
                '" />'
              );
            } else if (jsonObj.type === 'form') {
              let formInfo =
                '<b> Form: </b><br/>' +
                jsonObj.content.text +
                '  (' +
                jsonObj.content.message +
                ')<br/>';
              let fields = jsonObj.content.fields;
              for (let i = 0; i < fields.length; i++) {
                formInfo =
                  formInfo + '<span> Field: ' + fields[i].name + '</span><br/>';
              }
              return formInfo;
            } else if (jsonObj.type === 'video') {
              let videoUrl = jsonObj.content.url;
              if (videoUrl.includes('embed')) {
                return (
                  '<iframe width="100%" src="' +
                  jsonObj.content.url +
                  '" frameborder="0" allowfullscreen></iframe>'
                );
              } else {
                return '<a href="' + videoUrl + '">' + videoUrl + '</a>';
              }
            } else {
              return jsonObj.type;
            }
          }
        }
        return '';
      } catch (e) {
        // eslint-disable-next-line no-console
        console.log(e);
        return '';
      }
    },
  },
  methods: {
    isImageFile(fileName) {
      const allowedExts = [
        'apng',
        'gif',
        'avif',
        'jpg',
        'jpeg',
        'jfif',
        'pjpeg',
        'pjp',
        'png',
        'svg',
        'webp',
      ];
      const ext = fileName.substring(fileName.lastIndexOf('.') + 1);
      return allowedExts.includes(ext);
    },
    loadFileUrl() {
      this.file = _defaultFile();
      const chat = _.cloneDeep(this.chat);
      let jsonObj = JSON.parse(chat[11]);
      if (jsonObj) {
        if (_.isNumber(jsonObj.event)) {
          if (jsonObj.event === 5) {
            const fileUploadId = jsonObj.fileUploadId;
            this.httpclient
              .get('api/chatHistories/getFileUrl', {
                params: { fileUploadId: fileUploadId },
              })
              .then((resp) => {
                this.file = resp.data;
              });
          }
        }
      }
    },
    downloadFile(url, fileName) {
      this.isDownloading = true;
      this._downloadFileWithBlob(url, fileName).then(() => {
        this.isDownloading = false;
      });
    },
  },
  filters: {
    chatDate(data) {
      return dayjs(data).format('hh:mm:ss A');
    },
  },
};

function _defaultFile() {
  return { signedUrl: '', originalFileName: '', contentType: '' };
}
</script>

<style lang="scss" scoped>
.chat-text-container {
  display: inline-block;
}

.chat-text {
  background-color: rgb(240, 244, 248);
  padding: 0.5rem 1rem;
  border-radius: 5px;
  font-size: 14px;
}

.chat-sub-header {
  display: inline-block;
  font-size: 11px;
  padding-bottom: 0.5em;
}

.chat-container {
  margin-bottom: 0.8rem;
}

.chatBot {
  background-color: #c7edfc;
}
</style>
