<template>
  <div>
    <div v-for="(chat, index) in chats" :key="index">
      <ChatTextItem :chat="chat" />
    </div>
  </div>
</template>

<script>
import ChatTextItem from '@/components/ChatHistories/ChatTextItem';

export default {
  name: 'ChatText',
  props: ['chats'],
  components: { ChatTextItem },
  data() {
    return {
      options: [''],
    };
  },
  methods: {},
};
</script>
